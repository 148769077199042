<template>
    <div class='grid'>

        <Toast />
        <ConfirmDialog></ConfirmDialog>
        <SalaryReport :report='salaryReport'></SalaryReport>
        
        <div class='col-12 md:col-6 lg:col-12'>
        
        <div class='p-0 h-full flex flex-column'>

        <div class='grid'>
            <div class='col-12'>

                <div>
                    <Toolbar class='mb-2'>
                        <template v-slot:start>
                            <div >
                                <Calendar id='selectedMonth' view='month' dateFormat='MM - yy' :showIcon='true' :showButtonBar='true' v-model='selectedSalaryDate' @date-select='salaryDateChanged'></Calendar>
                            </div>
                        </template>

                        <template v-slot:end>

                            <Button label='Çıkanları Hesapla' icon='pi pi-plus' class='p-button-help mr-2' @click='partialCalculateDialogVisible = true' />
                            
                            <Button label='Maaş Hesapla' icon='pi pi-money-bill' class='p-button mr-2' @click='calculateDialog = true' />

                            <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success mr-2' @click='exportExcel'></Button>

                            <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger' />

                            <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                                <h3>Maaş Hesaplama</h3>

                                <p class="text-lg">
                                    Personel maaşlarını hesaplamadan önce avans, mesai ve var ise ücretli - ücretsiz izinlerin tamamının girildiğinden emin olunuz.<br>
                                    <br>
                                    Maaş hesapla fonksiyonu ay bitiminde kullanılmalıdır.<br>
                                    <br>
                                    Çıkanları hesapla fonksiyonu ay içerisinde işten çıkmış personellerin maaşlarının hesaplaması içindir. <br>
                                    <br>
                                    Hesaplamaları kontrol ettikten sonra Proje Müdür Onayı işaretlemeniz gerekmektedir. Onayladığınuz maaşlar muhasebe biriminin listesinde görüntülenecektir.<br>
                                    <br>
                                    Maaş ödeme veya diğer ödeme seçeneklerinden biri onaylanmış ise silme işlemi yapamazsınız.<br>
                                    <br>
                                    Düzeltme işlemi için silip yeniden hesapla işlemi yaptığınızda sadece o personele ait veriler yeniden yüklenir.<br>
                                    <br>
                                    Maaş ödeme fonksiyonu muhasebe tarafından yönetilmektedir.<br>
                                    <br>
                                    Diğer ödemeyi yaptığınızda onaylamanız gerekmektedir.<br>
                                    <br>  
                                </p>
                            </Sidebar>

                        </template>
                    </Toolbar>


                    <DataTable ref='dt' :value='dataList' dataKey='id' :paginator='true' :rows='100' :filters='filters' :loading='loadingIndicator' paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown' :rowsPerPageOptions='[5,10,25,50,100,200,300]' currentPageReportTemplate='{first} Sayfada {last} Kayıt Gösteriliyor. Toplam Kayıt: {totalRecords}' responsiveLayout='scroll' showGridlines stripedRows>

                        <template #header>
                            <div class='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
                                <h5 class='mb-3 mt-3'>Maaş Listesi</h5>
                                <span class='block mt-2 md:mt-0 p-input-icon-left'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama...' />
                            </span>
                            </div>
                        </template>

                        <template #loading>
                            Maaşlar Hesaplanıyor. Lütfen Bekleyin.
                        </template>

                        <template #empty>
                            Seçili Projeye Ait {{ formatDateYA(selectedSalaryDate) }} Tarihli Bordro Bulunamadı.
                        </template>

                        <Column header='Profil' bodyClass="text-center" style='width:4rem'>
                            <template #body='{data}'>
                                <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employee.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='editPersonel(data)'/>
                            </template>
                        </Column>

                        <Column field='employee.name' header='Ad' :sortable='false'>
                            <template #body='{data}'>
                                <a @click='editPersonel(data)' class="cursor-pointer">
                                    {{ data.employee.name }}
                                </a>
                            </template>
                        </Column>

                        <Column field='employee.surName' header='Soyad' :sortable='false'>
                            <template #body='{data}'>
                                <a @click='editPersonel(data)' class="cursor-pointer">
                                    {{ data.employee.surName }}
                                </a>
                            </template>
                        </Column>

                        <Column field='employee.surName' header='Bordro Tarihi' :sortable='false'>
                            <template #body='slotProps'>
                                {{ formatDateWithoutDayName(slotProps.data.workStartDate) }} <br> {{ formatDateWithoutDayName(slotProps.data.workEndDate) }}
                            </template>
                        </Column>

                        <Column field='afterDayOffsWorkDays' header='Gün Sayısı' :sortable='true'>
                            <template #body='slotProps'>
                                {{ slotProps.data.afterDayOffsWorkDays }} Gün
                            </template>
                        </Column>

                        <Column field='dayOff' header='Ücretsiz İzin' :sortable='false'>
                            <template #body='slotProps'>
                                {{ slotProps.data.dayOff }} Gün
                            </template>
                        </Column>

                        <Column field='totalDayOffPrice' header='Ücretsiz İzin Tutar' :sortable='false'>
                            <template #body='slotProps'>
                                - {{ formatPrice(slotProps.data.totalDayOffPrice) }}
                            </template>
                        </Column>

                        <Column field='totalOvertimeHours' header='Mesai Saati' :sortable='false'>
                            <template #body='slotProps'>
                                {{ slotProps.data.totalOvertimeHours }}
                            </template>
                        </Column>

                        <Column field='totalOverTimePrice' header='Mesai Ücreti' :sortable='false'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.totalOverTimePrice) }}
                            </template>
                        </Column>    



                        <Column field='officialAllowancePrice' header='Maaş Avans' :sortable='false'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.officialAllowancePrice) }}
                            </template>
                        </Column>
                        
                        <Column field='unOfficialAllowancePrice' header='Diğer Avans' :sortable='false'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.unOfficialAllowancePrice) }}
                            </template>
                        </Column>
                        <Column field='allowancePrice' header='Ödenen Avans' :sortable='false'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.allowancePrice) }}
                            </template>
                        </Column>

                        <Column field='totalBonus' header='Ödenen Prim' :sortable='false'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.totalBonus) }}
                            </template>
                        </Column>
                        <Column field='officialTotalPrice' header='Maaş' :sortable='true'>
                            <template #body='slotProps'>
                                <b class="text-green-600">{{ formatPrice(slotProps.data.officialTotalPrice) }}</b>
                            </template>
                        </Column>

                        <Column field='unOfficialTotalPrice' header='Diğer' :sortable='true'>
                            <template #body='slotProps'>
                                <b class="text-red-600">{{ formatPrice(slotProps.data.unOfficialTotalPrice) }}</b>
                            </template>
                        </Column>

                        <Column field='paymentTotal' header='Ödenecek Toplam' :sortable='true'>
                            <template #body='slotProps'>
                                <b>{{ formatPrice(slotProps.data.paymentTotal) }}</b>
                            </template>
                        </Column>
                        <Column field='paymentTotal' header='Maaş Toplam' :sortable='true'>
                            <template #body='slotProps'>
                                <b>{{ formatPrice(slotProps.data.paymentTotal + slotProps.data.allowancePrice) }}</b>
                            </template>
                        </Column>
                        <Column field='bankName' header='Banka / IBAN' :sortable='false'>
                            <template #body='slotProps'>
                                {{ slotProps.data.bankName }}
                                <br>
                                {{ slotProps.data.iban }}
                            </template>
                        </Column>

                        <Column field='isApproved' header='Müdür Onayı' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                            <template #body='{data}'>
                                <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isApproved, 'text-pink-500 pi-times-circle': !data.isApproved}" @click='approveSalary(data.id, !data.isApproved)'></i>
                            </template>
                            <template #filter={filterModel}>
                                <TriStateCheckbox v-model='filterModel.value' />
                            </template>
                        </Column>

                        <Column field='isOfficialPaymentApproved' header='Maaş Ödeme' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                            <template #body='{data}'>
                                <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isOfficialPaymentApproved, 'text-pink-500 pi-times-circle': !data.isOfficialPaymentApproved}"></i>
                            </template>
                            <template #filter={filterModel}>
                                <TriStateCheckbox v-model='filterModel.value' />
                            </template>
                        </Column>

                        <Column field='isUnOfficialPaymentApproved' header='Diğer Ödeme' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                            <template #body='{data}'>
                                <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isUnOfficialPaymentApproved, 'text-pink-500 pi-times-circle': !data.isUnOfficialPaymentApproved}" @click='unOfficialPaymentUpdate(data.id, !data.isUnOfficialPaymentApproved)'></i>
                            </template>
                            <template #filter={filterModel}>
                                <TriStateCheckbox v-model='filterModel.value' />
                            </template>
                        </Column>

                        <Column style='width:5rem;' header='Personel Detay' bodyClass='text-center' class="hidden">
                            <template #body='slotProps'>
                                <Button icon='pi pi-user' class='p-button-rounded p-button-primary' @click='editPersonel(slotProps.data)' />
                            </template>
                        </Column>

                        <Column style='width:5rem;' header='Sil' bodyClass='text-center'>
                            <template #body='slotProps'>
                                <Button icon='pi pi-trash' class='p-button-rounded p-button-primary' @click='editSalary(slotProps.data)' />
                            </template>
                        </Column>

                    </DataTable>

                    <Dialog v-model:visible='calculateDialog' :style="{width: '450px'}" header='Hakediş Hesapla' :modal='true'>
                        <template #header>
                            <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' /> <b>Hakediş Hesapla</b>
                        </template>
                        <div class='flex align-items-center justify-content-center'>
                        <span> Maaş hesaplaması yapmadan önce avans, mesai ve var ise ücretsiz izinlerin eklenildiğinden emin olunuz.
                            <br>  <br>
                            <span v-if='wrongMonth'> Çalışma Takviminde ay henüz tamamlanmamış. Maaş hesaplamasına devam etmeniz farklı hatalı sonuçlar verebilir.  Emin misiniz? <br><br> </span>
                            <b>{{ formatDateYA(this.selectedSalaryDate) }}</b> ayına ait bordroların hesaplanmasını istiyormusunuz?</span>
                        </div>
                        <template #footer>
                            <Button label='Vazgeç' icon='pi pi-times' class='p-button-text' @click='hideCalculateDialog' />
                            <Button label='Evet' icon='pi pi-check' class='p-button-text' v-bind:disabled='isProcessing' @click='calculateSalaries(false)' />
                        </template>
                    </Dialog>

                    <Dialog v-model:visible='partialCalculateDialogVisible' :style="{width: '450px'}" header='Kısmi Hesaplama' :modal='true'>
                        <template #header>
                            <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' /> <b>Kısmi Hesaplama</b>
                        </template>
                        <div class='flex align-items-center justify-content-center'>
                      <span>
                            Kısmi hesaplama işten bir nedenle ayrılan personelin maaşını hesaplamak için kullanılır. Bu fonksiyonu kullanmadan önce çıkış işlemlerini yapınız.
                            <br><br> 
                            <b>{{ formatDateYA(this.selectedSalaryDate) }}</b> ayına ait ay içinde çıkan çalışanların bordroların hesaplanmasını istiyormusunuz?</span>
                        </div>
                        <template #footer>
                            <Button label='Vazgeç' icon='pi pi-times' class='p-button-text' @click='partialCalculateDialogVisible = false' />
                            <Button label='Evet' icon='pi pi-check' class='p-button-text' v-bind:disabled='isProcessing' @click='calculateSalaries(true)' />
                        </template>
                    </Dialog>

                    <Dialog v-model:visible='deleteSalaryDialog' :style="{width: '450px'}" header='UYARI' :modal='true'>
                        <div class='flex align-items-center justify-content-center'>
                            <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' />
                            <span> {{ selectedSalary.employee.name }} {{ selectedSalary.employee.surName }} isimli personele ait {{ formatDateYA(selectedSalaryDate)
                                }} tarihli maaş hesaplamasını silmek istediğinize emin misiniz? Silme işlemi geri alınamaz.</span>
                        </div>
                        <template #footer>
                            <Button label='Vazgeç' icon='pi pi-times' class='p-button-text' @click='deleteSalaryDialog = false' />
                            <Button label='Evet' icon='pi pi-check' class='p-button-text' v-bind:disabled='isProcessing' @click='deleteSalary' />
                        </template>
                    </Dialog>

                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import moment from 'moment';
import { showErrorMessage, showSuccessMessage } from '../../components/shared/toast/toastFunction';
import EmployeeSalaryService from '../../services/employeeSalaryService';
import SalaryReport from '../../components/shared/SalaryReport';
import { getActiveProjectId, getUserId } from '../common/commonConstantFunctions';

export default {
    components: { SalaryReport },
    data() {
        return {
            isProcessing: false,
            selectedSalaryDate: null,
            year: 1900,
            month: 1,
            loadingIndicator: false,
            projectId: 0,
            userId: 0,
            calculateDialog: false,
            partialCalculateDialogVisible: false,
            deleteSalaryDialog: false,
            dataList: null,
            submitted: false,
            dataItem: {
                projectId: 0,
                paymentDate: '',
                managerUserId: 0,
            },
            salaryReport: {},
            selectedSalary: {},
            display: false,
            visibleLeft: false,
            wrongMonth: false,
            filters:{},

        };
    },
    employeeSalaryService: null,
    created() {
        this.loadingIndicator = true;
        this.selectedMonthParameter = this.$route.params.selectedMonth;
        if (this.selectedMonthParameter == null) {
            this.selectedSalaryDate = moment().toDate();
        } else {
            this.selectedSalaryDate = moment.unix(this.selectedMonthParameter / 1000).toDate();
        }
        this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
        this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
        this.wrongMonth = true;
        this.paymentDateAsTimeStamp = moment(this.paymentDate).valueOf();
        this.projectId = getActiveProjectId();
        this.userId = getUserId();
        this.employeeSalaryService = new EmployeeSalaryService();
        this.initFilters();
    },

    async mounted() {
        await this.refreshSalaries();

    },
    methods: {
        async officialPaymentUpdate(id, newStatus) {
            this.$confirm.require({
                message: 'Maaş Ödeme Statüsü Değişecek Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    await this.employeeSalaryService.updateOfficialPaymentStatus(id, newStatus);
                    await this.refreshSalaries();
                },

            });
        },
        async unOfficialPaymentUpdate(id, newStatus) {
            this.$confirm.require({
                message: 'Diğer Maaş Ödeme Statüsü Değişecek Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    await this.employeeSalaryService.updateUnOfficialPaymentStatus(id, newStatus);
                    await this.refreshSalaries();
                },

            });

        },
        async approveSalary(id, newStatus) {
            this.$confirm.require({
                message: 'Maaş Hesaplama Statüsü Değişecek Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    await this.employeeSalaryService.updateApproveStatus(id, newStatus);
                    await this.refreshSalaries();
                },

            });

        },
        hideCalculateDialog() {
            this.calculateDialog = false;
        },

        async salaryDateChanged() {
            this.loadingIndicator = true;
            this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
            this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
            if (this.month >= moment(moment().toDate()).toDate().getMonth() + 1) {
                this.wrongMonth = true;
            } else {
                this.wrongMonth = false;
            }

            await this.refreshSalaries();
        },

        async deleteSalary() {
            this.isProcessing = true;
            if (this.selectedSalary.isOfficialPaymentApproved || this.selectedSalary.isUnOfficialPaymentApproved) {
                this.isProcessing = false;
                showErrorMessage(this, 'Onaylanmış Maaş Hesaplaması Silinemez');
                return;
            }
            await this.employeeSalaryService.deleteEmployeeSalary(this.selectedSalary.id);
            await this.refreshSalaries();
            this.deleteSalaryDialog = false;
            this.isProcessing = false;
        },

        async calculateSalaries(calculatePartial) {
            this.isProcessing = true;
            this.loadingIndicator = true;
            let calculateResponse = await this.employeeSalaryService.calculateEmployeeSalaries(this.projectId, this.year, this.month, this.userId, calculatePartial);
            if (calculateResponse.isSuccess) {
                await this.refreshSalaries();
                showSuccessMessage(this, 'Hesaplama İşlemi Başarılı');
            } else {
                this.isProcessing = false;
                showErrorMessage(this, calculateResponse.message);
            }
            if (calculatePartial) {
                this.partialCalculateDialogVisible = false;
            } else {
                this.calculateDialog = false;
            }
            this.isProcessing = false;
            this.loadingIndicator = false;
        },
        async exportExcel() {
            this.loadingIndicator = true;
            let excelExports = await this.employeeSalaryService.getEmployeeSalariesExcel(this.projectId, this.year, this.month);
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Maaşlar " + moment(this.paymentDate).format('DD.MM.YYYY') + ".xlsx";
            link.click();
            this.loadingIndicator = false;
        },
        async refreshSalaries() {
            this.loadingIndicator = true;
            let getResponse = await this.employeeSalaryService.getEmployeeSalaries(this.projectId, this.year, this.month);
            if (getResponse.isSuccess) {
                this.dataList = getResponse.data;
                await this.refreshSalaryReport();
                this.loadingIndicator = false;
            }
        },

        async refreshSalaryReport() {
            let getResponse = await this.employeeSalaryService.getEmployeeSalaryReport(this.projectId, this.year, this.month);
            if (getResponse.isSuccess) {
                this.salaryReport = getResponse.data;
            }
        },
        hideUpdatePriceDialog() {
            this.priceUpdateDialog = false;
            this.submitted = false;
        },
        hideDialog() {
            this.updateActualValueDialog = false;
            this.submitted = false;
        },
        editSalary(employeeSalary) {
            this.selectedSalary = employeeSalary;
            this.deleteSalaryDialog = true;
        },
        editPersonel(item) {
            this.$router.push({ name: 'editProjectEmployee', params: { projectEmployeeId: item.projectEmployeeId, employeeId: item.employee.id } });
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        formatDate(value) {
            moment.locale('tr');
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY - dddd');
        },
        formatDateWithoutDayName(value) {
            moment.locale('tr');
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatDateYA(value) {
            moment.locale('tr');
            let newDate = moment(value);
            return newDate.format('YYYY - MM');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },


    },
};
</script>

<style scoped lang='scss'>
</style>



